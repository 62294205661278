export default {
  "apiError401": "Identifiants incorrects",
  "apiError404": "Service non disponible",
  "apiError405_406": "Requête invalide",
  "apiError500": "Erreur de connexion",
  "apiErrorUndefined": "Erreur générale de l'application",
  "invalidKey": "Clé invalide",
  "recordSuccess": "Enregistrement terminé",
  "emailSuccess": "E-mail envoyé",
  "passwordUpdated": "Mot de passe mis à jour",
  "copiedClipboard": "Copié dans le presse-papiers",
  "guestDeleted": "Invité supprimé",
  "urlClipboard": "Lien copié",
  "changeSave": "Modifications enregistrées",
  "dataSave": "Données enregistrées avec succès"
};
  