export default {
    "account": "Il mio account",
    "greeting": "Ciao, {name}!",
    "my_stays": {
        "title": "I miei soggiorni",
        "subtitle_active": "Gestisci i tuoi soggiorni",
        "subtitle_inactive": "Crea il tuo prossimo soggiorno"
    },
    "personal_info": {
        "title": "Informazioni personali",
        "description": "Configura le tue informazioni personali"
    },
    "language" : {
        "title": "Lingua",
        "description": "Seleziona la lingua predefinita"
    },
    "logout": "Disconnettersi",
    "share_stay": "Condividi Soggiorno",
    "page_personal_info": {
        "title": "Informazioni personali",
        "change_photo": "Cambia foto",
        "change_password": "Cambia password",
        "btn_save": "Salva",
        "form": {
            "name": {
                "label": "Nome",
                "placeholder": "Inserisci il tuo nome"
            },
            "last_name": {
                "label": "Cognome",
                "placeholder": "Inserisci il tuo cognome"
            },
            "email": {
                "label": "Email",
                "placeholder": "Inserisci la tua email"
            },
            "number_phone": {
                "label": "Numero di telefono",
                "placeholder": "Inserisci il tuo numero di telefono"
            },
            "password": {
                "label": "Password",
                "placeholder": "Inserisci la tua password"
            }
        }
    },
    "book_next_stay": {
        "title": "Prenota il tuo prossimo soggiorno",
        "description": "Pianifica la tua prossima fuga"
    },
    "rewards": {
            "title": "Invita un amico",
            "description": "Condividi vantaggi esclusivi",
            "button-recommend": "Raccomanda un amico ora",
        "referrals": {
            "title": "Raccomanda un amico!",
            "discount": "{amount} di sconto",
            "description": "Invia il tuo codice di riferimento. Otterrà {amount} di sconto grazie al tuo codice."
        },
        "referent": {
            "title": "Invita un amico e vincete entrambi!",
            "titleReferred": "{amount} di sconto per il raccomandato",
            "descerptionReferred": "Invia il tuo codice di riferimento. Otterrà {amount} di sconto grazie al tuo codice.",
            "titleReferent": "Ricevi {amount} di sconto",
            "descerptionReferent": "Una volta convalidato e utilizzato il codice del riferimento, otterrai {amount} di sconto."
        },
        "titleReferentProfile": "Guadagna denaro",
        "descriptionReferentProfile": "Raccomanda un amico per ottenere vantaggi"
    }


};
