export default {
    "title": "Mensajes",
    "availabilty": "Disponible",
    "not-availabilty": "No disponible",
    "languages-text":"Te damos la bienvenida al chat!<br>Puedes comunicarte con nuestro personal en estos idiomas:",
    "automatic-response": "Respuesta automática",
    "input": "Escribe un mensaje...",
    "horary-title": "Horarios del chat",
    "nameDayLunes": "Lunes",
    "nameDayMartes": "Martes",
    "nameDayMiércoles": "Miércoles",
    "nameDayJueves": "Jueves",
    "nameDayViernes": "Viernes",
    "nameDaySábado": "Sábado",
    "nameDayDomingo": "Domingo",
    "tabs": {
        "inbox": "buzón", 
        "chat": "chat"
    }
}