export default {
    "breadcrumbs": {
        "explore": "Erkunden",
    },
    "list-page": {
        "title": "Erkunden in",
        "text-count-list": "{count} Orte gefunden",
        "text-count-list-search-active": "Alle Orte durchsuchen nach",
        "text-count-list-search": "{count} Orte gefunden für",
        "text-search-not-found": "Verfeinern Sie Ihre Suche oder versuchen Sie es mit einer anderen Kategorie",
        "btn-load-more": "Mehr laden"
    },
    "results": "Keine Ergebnisse...",
    "seaching": "Suche...",
    "categorySearch": "gefunden",
    "city": "Stadt",
    "placeholder-search": "Schreibe etwas",
    "types": {
        "que-visitar": "Was zu besuchen",
        "donde-comer": "Wo essen",
        "ocio": "Freizeit"
    },
    "categories": {
        "monumentos": "Denkmäler",
        "museos": "Museen",
        "zonas-verdes": "Grünflächen",
        "cafeterias-y-postres": "Cafés und Desserts",
        "restaurantes": "Restaurants",
        "vida-nocturna": "Nachtleben",
        "copas": "Becher",
        "compras": "Einkaufen",
        "otros": "Andere",
    },
    "detail": {
        "titleList": "Erkunden",
        "opinionsTitle": "Meinungen zu touristischen Informationen",
        "opinionsWord": "Meinungen",
        "recommendationWord": "Empfehlung",
        "featured": "Hervorgehoben",
        "callWord": "Anrufen",
        "dataInterest": {
            "title": "Interessante Daten",
            "priceRange": "Preisspannen",
            "diet": "Spezielle Diäten",
            "others": "Andere",
        },
        "hours": {
            "title": "Öffnungszeiten",
            "monday": "Montag",
            "tuesday": "Dienstag",
            "wednesday": "Mittwoch",
            "thursday": "Donnerstag",
            "friday": "Freitag",
            "saturday": "Samstag",
            "sunday": "Sonntag"
        },
        "specialRegimes": "Sonderregelungen",
        "additionalInformation": "Zusätzliche Informationen",
        "copy": "Kopieren",
        "showLess": "Mostra meno",
        "showMore": "Mehr anzeigen",
        "recommended": "Empfohlen",
        "recommendedTitle": "Empfehlung des {lodging}",
        "buttoms": {
            "web": "Web",
            "call": "Anrufen",
            "menu": "Menü",
            "email": "E-mail",
        },
        "locationWord": "Standort",
        "starWord": "Stern",
        "starsWord": "Sterne",
        "ratingTitle": "Bewertung",
        "noComment": "Kein Kommentar geschrieben",
        "seeMoreComment": "Mehr sehen",
        "seeAllComments": "Alle Meinungen ansehen",
        "titleAllReviews": "Meinungen zu touristischen Informationen",
        "exploreIn": "Erkunden in",
        "filters": {
            "title": "Entfernung zur Unterkunft",
            "ratings": "Bewertungen",
            "distanceLogding":"Distance from",
            "recommended": "Empfohlen",
            "score": "Punktzahl",
            "star": "Stern",
            "stars": "Sterne",
            "remove": "Alle entfernen",
            "apply": "Filter anwenden",
            "distante": {
                "oneKm": "Zwischen 1 km und 3 km",
                "threeKm": "Zwischen 3 km und 5 km",
                "fiveKm": "Zwischen 5 km und 10 km",
                "tenKm": "10 km+"
            }
        }
    },
}
