export default {
    "title": "Messages",
    "availabilty": "Available",
    "not-availabilty": "Not available",
    "languages-text": "Welcome to the chat!<br>You can communicate with our staff in these languages:",
    "automatic-response": "Automatic response",
    "input": "Write a message...",
    "horary-title": "Chat times",
    "nameDayLunes": "Monday",
    "nameDayMartes": "Tuesday",
    "nameDayMiércoles": "Wednesday",
    "nameDayJueves": "Thursday",
    "nameDayViernes": "Friday",
    "nameDaySábado": "Saturday",
    "nameDayDomingo": "Sunday",
    "tabs": {
        "inbox": "inbox", 
        "chat": "chat"
    }
}
