export default {
    "facilitiesWord":"Facilities",
    "horaryWord":"Schedule",
    "detailPage": {
        "sectionSchedules": {
            "title": "Schedules",
            "openAlways": "Open every day 24 hours",
            "empty": "No schedules specified. Please inquire via chat",
            "days": {
                "Lunes": "Monday",
                "Martes": "Tuesday",
                "Miércoles": "Wednesday",
                "Jueves": "Thursday",
                "Viernes": "Friday",
                "Sábado": "Saturday",
                "Domingo": "Sunday"
            }
        }
    }
}