export default {
    "breadcrumbs": {
        "explore": "Explore",
    },
    "list-page": {
        "title": "Explore in",
        "text-count-list": "{count} places found",
        "text-count-list-search": "{count} places found for",
        "text-count-list-search-active": "Search all places for",
        "text-search-not-found": "Refine your search or try another category",
        "btn-load-more": "Load more"
    },
    "results":'Without results...',
    "seaching":'Searching...',
    "placeholder-search":"Write something",
    "categorySearch":'found',
    "city":"City",
    "types":{
        "que-visitar":"What to visit",
        "donde-comer":"Where to eat",
        "ocio":"Leisure"
    },
    "categories":{
        "monumentos":"Monuments",
        "museos":"Museums",
        "zonas-verdes":"Green zones",
        "cafeterias-y-postres":"Cafeterias and desserts",
        "restaurantes":"Restaurants",
        "vida-nocturna":"Night life",
        "copas":"Cups",
        "compras":"Shopping",
        "otros":"Others",
    },
    "detail":{
        'titleList': "Explore",
        "opinionsTitle":"Opinions on tourist information",
        "opinionsWord":"Opinions",
        "recommendationWord": "Recommendation",
        "featured": "Featured",
        "callWord": "Call",
        "dataInterest":{
            "title":"Data of interest",
            "priceRange":'Price ranges',
            "diet":"Special diets",
            "others":"Others",
        },
        "specialRegimes": "Special regimes",
        "additionalInformation": "Additional information",
        "copy": "Copy",
        "showLess": "Show less",
        "showMore": "Show more",
        "recommended": "Recommended",
        "recommendedTitle": "Recommendation of {lodging}",
        "buttoms": {
            "web": "Web",
            "call": "Call",
            "menu": "Menu",
            "email": "E-mail",
        },
        "hours": {
            "title": "Schedules",
            "monday": "Monday",
            "tuesday": "Tuesday",
            "wednesday": "Wednesday",
            "thursday": "Thursday",
            "friday": "Friday",
            "saturday": "Saturday",
            "sunday": "Sunday"
        },
        "locationWord": "Location",
        "starWord": "Star", 
        "starsWord": "Stars",
        "ratingTitle": "Rating",
        "noComment": "No comment written",
        "seeMoreComment": "See more",
        "seeAllComments": "See all opinions",
        "titleAllReviews": "Opinions on tourist information",
        "exploreIn":"Explore in",
        "filters":{
            "title":"Filter by",
            "ratings":"Ratings",
            "distanceLogding":"Distance from",
            "recommended": "Recommended",
            "score": "Score",
            "star":"star",
            "stars":"stars",
            "remove":"Remove all",
            "apply":"Apply filters",
            "distante": {
                "oneKm": "Between 1 km and 3 km",
                "threeKm": "Between 3 km and 5 km",
                "fiveKm": "Between 5 km and 10 km",
                "tenKm": "10 km+"
            }
        }
    },
}