export default {
    "title": "Mensagens",
    "availabilty": "Disponível",
    "not-availabilty": "Indisponível",
    "languages-text": "Bem-vindo ao chat!<br>Você pode se comunicar com nosso pessoal nestes idiomas:",
    "automatic-response": "Resposta automática",
    "input": "Escreva uma mensagem...",
    "horary-title": "Horário de chat",
    "nameDayLunes": "Segunda-feira",
    "nameDayMartes": "Terça-feira",
    "nameDayMiércoles": "Quarta-feira",
    "nameDayJueves": "Quinta-feira",
    "nameDayViernes": "Sexta-feira",
    "nameDaySábado": "Sábado",
    "nameDayDomingo": "Domingo",
    "tabs": {
        "inbox": "entrada", 
        "chat": "chat"
    }
}
