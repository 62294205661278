/* eslint-disable */

export const authPath = 'auth'

export const homePath = 'home'
export const hotelPath = 'hotel'
export const staySurveyPath = 'staySurvey'
export const hotelOtaPath = 'hotelOta'
export const experiencePath = 'experience'
export const conforServicePath = 'confort'
export const transportServicePath = 'transport'
export const placePath = 'place'
export const utilityPath = 'utility'
export const cityPath = 'city'
export const stayPath = 'stay'
export const guestPath = 'guest'
export const chatPath = 'chat'
export const utilsPath = 'utils'
export const facilityPath = 'facility'
export const queryPath = 'query'
export const querySettingsPath = 'query-settings'
export const stayAccessPath = 'stayAccess'
export const requestSettingsPath = 'request-settings'
export const languagePath = 'language'
export const hotelsReviewPath = 'hotels'
export const chainPath = 'chain'