export default {
    "title": "Messages",
    "availabilty": "Disponible",
    "not-availabilty": "Indisponible",
    "languages-text": "Bienvenue dans le chat !<br>Vous pouvez communiquer avec notre personnel dans ces langues :",
    "automatic-response": "Réponse automatique",
    "input": "Écrivez un message...",
    "horary-title": "Heures de discussion",
    "nameDayLunes": "Lundi",
    "nameDayMartes": "Mardi",
    "nameDayMiércoles": "Mercredi",
    "nameDayJueves": "Jeudi",
    "nameDayViernes": "Vendredi",
    "nameDaySábado": "Samedi",
    "nameDayDomingo": "Dimanche",
    "tabs": {
        "inbox": "réception", 
        "chat": "chat"
    }
}
