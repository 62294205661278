export default{
    "create":{
        "title": "Crea il tuo soggiorno",
        "check-date": {
            "label": "Data di Check-in/Check-out",
            "placeholder": "gg/mm/aaaa - gg/mm/aaaa",
        },
        "continue-button": "Crea e accedi al soggiorno",
    },
    "share": {
        "title": "Unisciti al nostro soggiorno presso {hotel}",
        "text": "Unisciti ora e goditi tutti i vantaggi della WebApp per sfruttare al massimo la nostra esperienza"
    },
    "detail": {
        "title": "Le mie permanenze",
        "createBtn": "Crea permanenza",
        "editBtn": "Modifica permanenza"
    },
    "edit": {
        "title": "Modifica permanenza",
        "information-stay": "Informazioni sulla permanenza",
        "input": {
            "medio-reserva": {
                "label": "Metodo di prenotazione",
                "placeholder": "Seleziona metodo di prenotazione",
                "n-room": "Nº stanza"
            }
        },
        "calendar": "Calendario",
        "guests": "Ospiti",
        "saveBtn": "Salva",
        "share": "Condividi permanenza",
        "guest": "Ospite",
        "completeCheckin": "Completa il check-in",
        "waitingGuest": "In attesa dell'accesso dell'ospite",
        "accessRoom": "Accedi alla permanenza",
        "active": "Risorsa",
        "pageWebHotel": "Sito web dell'hotel"
    },
    "reservation": {
        "title": "Prenota il tuo prossimo soggiorno",
        "button-reservation": "Prenota",
        "title-page": "Seleziona un alloggio da prenotare e ti reindirizzeremo al suo sito web."

    }

}
