export default {
  "title": "Services",
    "card-item": {
      "free": "Gratuit"
    },
    "confort": {
      "title": "Confort",
      "list-page": {},
      "detail-page": {}
    },
    "transport": {
      "title": "Transport",
      "list-page": {},
      "detail-page": {}
    },
    "activity": {
      "title": "Activités",
      "list-page": {},
      "detail-page": {}
    }
  }