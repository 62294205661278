export default {
    "header": {
        "home": "inicio",
        "facilities": "instalaciones",
        "hotel": "hotel",
        "destination": "destino",
        "experiences": "experiencias",
        "messages": "mensajes"
    },
    "dropdownLanguage": {
        "title": "idioma"
    },
    "footer": {
        "legal-warning": "aviso legal",
        "privacy-policy": "política de privacidad",
        "privacy-cookies": "política de cookies",
        "terms-conditions": "términos y condiciones"
    },
    "input-search": {
        "search": "Buscar",
        "placeholder": "Destinos, experiencias...",
        "from": "desde"
    },
    // más traducciones...
  };