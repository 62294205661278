export default {
  "apiError401": "Credenciais incorretas",
  "apiError404": "Serviço não disponível",
  "apiError405_406": "Solicitação inválida",
  "apiError500": "Erro de conexão",
  "apiErrorUndefined": "Erro geral do aplicativo",
  "invalidKey": "Chave inválida",
  "recordSuccess": "Registro concluído",
  "emailSuccess": "E-mail enviado",
  "passwordUpdated": "Senha atualizada",
  "copiedClipboard": "Copiado para a área de transferência",
  "guestDeleted": "Hóspede excluído",
  "urlClipboard": "Link copiado",
  "changeSave": "Alterações salvas",
  "dataSave": "Dados salvos com sucesso"
};
