export default {
  "apiError401": "Falsche anmeldedaten",
  "apiError404": "Dienst nicht verfügbar",
  "apiError405_406": "Ungültige anfrage",
  "apiError500": "Verbindungsfehler",
  "apiErrorUndefined": "Allgemeiner anwendungsfehler",
  "invalidKey": "Ungültiger schlüssel",
  "recordSuccess": "Eintrag abgeschlossen",
  "emailSuccess": "E-mail gesendet",
  "passwordUpdated": "Passwort aktualisiert",
  "copiedClipboard": "In zwischenablage kopiert",
  "guestDeleted": "Gast gelöscht",
  "urlClipboard": "Link kopiert",
  "changeSave": "Änderungen gespeichert",
  "dataSave": "Daten erfolgreich gespeichert"
};
  