export default {
    "breadcrumbs": {
        "experiences": "Experiences",
    },
    "card-experience": {
        "hour": "hours",
        "aprox": "aproximately",
        "min-aprox": "minutes",
        "and": "and",
        "recommed": "recommended",
        "prominent": "prominent",
        "annulation-gratuite": "free cancelation",
        "non-refundable": "non refundable",
        "from":"From",
        "reviews": "reviews"
    },
    "list-page": {
        "title": "Experiences",
        "section-filter": {
            "label-search": "Filter by",
            "placeholder-input-search": "Enter a keyword",
            "label-price": "Price",
            "label-price-min": "Min. Price",
            "placeholder-price-max": "Minimum..",
            "label-price-max": "Max. Price",
            "placeholder-price-max": "Maximum..",
            "label-duration": "Duration",
            "cancellation-policy": {
                "title": "Cancellation Policy",
                "free-cancellation": "Free Cancellation"
              },
              "duration": {
                "title": "Duration",
                "one-hour": "Up to 1 hour",
                "one-four-hour": "Between 1 and 4 hours",
                "noon": "Half day",
                "whole-day": "Whole day"
              },
              "score": {
                "title": "Score",
                "star": "Stars"
              },
              "price": {
                "title": "Price",
                "0-100": "Between 0 € and 100 €",
                "100-250": "Between 100 € and 250 €",
                "250-500": "Between 250 € and 500 €",
                "500": "+500 €"
              }
        },
        "section-filter-history": {
            "btn-price": "Up to {price_max}",
            "btn-close": "Remove filter"
        },
        "btn-more-filter": "More filters",
        "btn-submit-filter": "Apply filters",
        "text-count-list": "Mixed experiences",
        "text-count-list-mobile": "{count} Experiences have been found.",
        "text-count-list-search-active": "Search all Experiences for",
        "text-count-list-search": "{count} Experiencias encontrados para",
        "experience.placeholder-search": "Search for an experience",
        "text-count-list-search": "{count} Experiences found for",
        "text-search-not-found": "Adjust your search or try removing filters.",
        "without-results": {
            "title": "No experiences found",
            "text": "No experiences found in this city. We invite you to explore other cities, where you will discover various experiences and activities."
        },
        "btn-load-more": "Load more"
    },
    "detail-page": {
        "tag-recommend": "Recommended",
        "tag-featured": "Featured",
        "text-variand-price": "Price varies depending on group size",
        "tag-ticket-mobile": "Mobile Ticket",
        "title-recomendation": "Recommendation",
        "title-description": "Description",
        "title-description-mobile": "Overview",
        "tooltip-language": "{language} and {numbers} more",
        "open-collapse-description": "See more",
        "close-collapse-description": "See less",
        "title-include": "What's Included",
        "title-location": "Location",
        "btn-eye-map": "View on Google Maps",
        "subtitle-point-start": "Starting Point",
        "subtitle-point-end": "End Point",
        "ends-point-at-meeting-point": "This activity ends at the initial meeting point",
        "title-other-information": "Additional Information",
        "title-politic-cancelation": "Cancellation Policy",
        "text-politic-standar-1": "You can cancel up to 24 hours before the experience for a full refund.",
        "text-politic-standar-2": "To get a full refund, you must cancel at least 24 hours before the start time of the experience.",
        "text-politic-standar-3": "Any changes made less than 24 hours before the start time of the experience will not be accepted.",
        "text-politic-standar-4": "If you cancel at least 24 hours before the start time of the experience, the amount paid will not be refunded.",
        "text-politic-standar-5": "Cut-off times are based on the local time of the experience.",
        "text-politic-notrembolsable": "This experience is non-refundable and cannot be changed for any reason. If you cancel or request a modification, the amount paid will not be refunded.",
        "opinions-word":"opinions",
        "variant-price":"The price varies depending on the size of the group",
        "when-experience-label":"When do you want to enjoy this experience?",
        "maximum-reserves-label":"You can reserve a maximum of {maxSeats} seats",
        "availability-label":"See availability",
        "free-cancellation-label":"Free cancellation",
        "non-refundable-cancellation-label":"Non-refundable",
        
        "check-the-availability-of-this-experience": "Check the availability of this experience",
        "btn-see-availability-on-viator": "See availability on Viator",
        "title-general-information": "General information",
        "tag-paper-ticket": "Paper ticket",
        "offered-in": "Offered in"
    }
}