export default {
    "breadcrumbs": {
        "explore": "Explore",
    },
    "list-page": {
        "title": "Explora en",
        "text-count-list": "{count} lugares encontradas",
        "text-count-list-search": "{count} lugares encontrados para",
        "text-count-list-search-active": "Buscar todos los lugares para",
        "text-search-not-found": "Ajusta tu búsqueda o intenta en otra categoría",
        "btn-load-more": "Cargar más"
        // "text-count-list-mobile": "Se han encontrado {count} experiencias.",
    },
    "results":'Sin resultados...',
    "seaching":'Buscando...',
    "categorySearch":'encontrados',
    "city":"Ciudad",
    "placeholder-search":"Escribe algo",
    "types":{
        "que-visitar":"Qué visitar",
        "donde-comer":"Dónde comer",
        "ocio":"Ocio"
    },
    "categories":{
        "monumentos":"Monumentos",
        "museos":"Museos",
        "zonas-verdes":"Zonas verdes",
        "cafeterias-y-postres":"Cafeterías y postres",
        "restaurantes":"Restaurantes",
        "vida-nocturna":"Vida nocturna",
        "copas":"Copas",
        "compras":"Compras",
        "otros":"Otros",
    },
    "detail":{
        'titleList': "Explora",
        "opinionsTitle":"Opiniones de la info. turística",
        "opinionsWord":"Reseñas",
        "recommendationWord": "Recomendación",
        "featured": "Destacado",
        "callWord": "Llamar",
        "dataInterest":{
            "title":"Datos de interés",
            "priceRange":'Rangos de precios',
            "diet":"Dietas especiales",
            "others":"Otros",
        },
        "specialRegimes": "Regímenes especiales",
        "additionalInformation": "Información adicional",
        "copy": "Copiar",
        "showLess": "Mostrar menos",
        "showMore": "Mostrar más",
        "recommended": "Recomendado",
        "recommendedTitle": "Recomendación del {lodging}",
        "buttoms": {
            "web": "Web",
            "call": "Llamar",
            "menu": "Menú",
            "email": "E-mail",
        },
        "hours": {
            "title": "Horarios",
            "monday": "lunes",
            "tuesday": "martes",
            "wednesday": "miércoles",
            "thursday": "jueves",
            "friday": "viernes",
            "saturday": "sábado",
            "sunday": "domingo"
        },
        "locationWord": "Ubicación",
        "starWord": "Estrella",
        "starsWord": "Estrellas",
        "ratingTitle": "Puntuación",
        "noComment": "No se escribio comentario",
        "seeMoreComment": "Ver más",
        "seeAllComments": "Ver todas las opiniones",
        "titleAllReviews": "Opiniones de la info. turística",
        "exploreIn":"Explora en",
        "filters":{
            "title":"Filtrar por",
            "distanceLogding":"Distancia del",
            "recommended": "Recomendado",
            "ratings":"Valoraciones",
            "star":"estrella",
            "score": "Puntaje",
            "stars":"estrellas",
            "remove":"Quitar todos",
            "apply":"Aplicar filtros",
            "distante": {
                "oneKm": "Entre 1 km y 3 km",
                "threeKm": "Entre 3 km y 5 km",
                "fiveKm": "Entre 5km y 10 km",
                "tenKm": "10 km+"
            }
        }
    },
}