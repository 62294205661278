export default {
    "title": "Services",
    "card-item": {
      "free": "Free"
    },
    "confort": {
      "title": "Comfort",
      "list-page": {},
      "detail-page": {}
    },
    "transport": {
      "title": "Transport",
      "list-page": {},
      "detail-page": {}
    },
    "activity": {
      "title": "Activities",
      "list-page": {},
      "detail-page": {}
    }
  }