export default {
  "title": "Services",
  "card-item": {
    "free": "Gratis"
  },
  "confort": {
    "title": "Confort",
    "list-page": {},
    "detail-page": {}
  },
  "transport": {
    "title": "Transporte",
    "list-page": {},
    "detail-page": {}
  },
  "activity": {
    "title": "Actividades",
    "list-page": {},
    "detail-page": {}
  }
}