export default  {
  "title": "Servizi",
    "card-item": {
      "free": "Gratis"
    },
    "confort": {
      "title": "Confort",
      "list-page": {},
      "detail-page": {}
    },
    "transport": {
      "title": "Trasporto",
      "list-page": {},
      "detail-page": {}
    },
    "activity": {
      "title": "Attività",
      "list-page": {},
      "detail-page": {}
    }
  }