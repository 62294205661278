export default {
    "facilitiesWord": "Instalações",
    "horaryWord": "Horário",
    "detailPage": {
        "sectionSchedules": {
            "title": "Horários",
            "openAlways": "Aberto todos os dias 24 horas",
            "empty": "Sem horários especificados. Consulte via chat",
            "days": {
                "Lunes": "Segunda-feira",
                "Martes": "Terça-feira",
                "Miércoles": "Quarta-feira",
                "Jueves": "Quinta-feira",
                "Viernes": "Sexta-feira",
                "Sábado": "Sábado",
                "Domingo": "Domingo"
            }
        }
    }
}
