export default {
    "header": {
        "home": "inizio",
        "facilities": "strutture",
        "hotel": "hotel",
        "destination": "destinazione",
        "experiences": "esperienze",
        "messages": "messaggi"
    },
    "dropdownLanguage": {
        "title": "lingua"
    },
    "footer": {
        "legal-warning": "avviso legale",
        "privacy-policy": "politica sulla privacy",
        "privacy-cookies": "politica sui cookie",
        "terms-conditions": "termini e condizioni"
    },
    "input-search": {
        "search": "Cerca",
        "placeholder": "Destinazioni, esperienze...",
        "from": "da"
    }
    // più traduzioni...
  };
