export default {
    "apiError401": "Credenciales incorrectas",
    "apiError404": "Servicio no disponible",
    "apiError405_406": "Solicitud invalida",
    "apiError500": "Error de conexión",
    "apiErrorUndefined": "Error general de la aplicación",
    "invalidKey": "Clave invalida",
    "recordSuccess": "Registro completado",
    "emailSuccess": "Correo enviado",
    "passwordUpdated": "Contraseña actualizada",
    "copiedClipboard": "Copiado al portapapeles",
    "guestDeleted": "Huesped eliminado",
    "urlClipboard": "Enlace copiado",
    "changeSave": "Cambios guardados",
    "dataSave": "Datos guardados con éxito"
};