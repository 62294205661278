export default {
    "facilitiesWord": "Einrichtungen",
    "horaryWord": "Zeitplan",
    "detailPage": {
        "sectionSchedules": {
            "title": "Zeitpläne",
            "openAlways": "Täglich 24 Stunden geöffnet",
            "empty": "Keine Zeitpläne angegeben. Bitte erkundigen Sie sich per Chat",
            "days": {
                "Lunes": "Montag",
                "Martes": "Dienstag",
                "Miércoles": "Mittwoch",
                "Jueves": "Donnerstag",
                "Viernes": "Freitag",
                "Sábado": "Samstag",
                "Domingo": "Sonntag"
            }
        }
    }
}
