export default {
    "facilitiesWord":"Installations",
    "horaryWord":"Calendrier",
    "detailPage": {
        "sectionSchedules": {
            "title": "Horaires",
            "openAlways": "Ouvert tous les jours 24h/24",
            "empty": "Aucun horaire spécifié. Veuillez vous renseigner via le chat",
            "days": {
                "Lunes": "Lundi",
                "Martes": "Mardi",
                "Miércoles": "Mercredi",
                "Jueves": "Jeudi",
                "Viernes": "Vendredi",
                "Sábado": "Samedi",
                "Domingo": "Dimanche"
            }
        }
    }
}