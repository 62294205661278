export default {
    "title": "Nachrichten",
    "availabilty": "Verfügbar",
    "not-availabilty": "Nicht verfügbar",
    "languages-text": "Willkommen im Chat!<br>Sie können in diesen Sprachen mit unserem Personal kommunizieren:",
    "automatic-response": "Automatische Antwort",
    "input": "Schreibe eine Nachricht...",
    "horary-title": "Chat-Stunden",
    "nameDayLunes": "Montag",
    "nameDayMartes": "Dienstag",
    "nameDayMiércoles": "Mittwoch",
    "nameDayJueves": "Donnerstag",
    "nameDayViernes": "Freitag",
    "nameDaySábado": "Samstag",
    "nameDayDomingo": "Sonntag",
    "tabs": {
        "inbox": "posteingang", 
        "chat": "chat"
    }
}
