export default {
  "title": "Serviços",
    "card-item": {
      "free": "Grátis"
    },
    "confort": {
      "title": "Conforto",
      "list-page": {},
      "detail-page": {}
    },
    "transport": {
      "title": "Transporte",
      "list-page": {},
      "detail-page": {}
    },
    "activity": {
      "title": "Atividades",
      "list-page": {},
      "detail-page": {}
    }
  }