export default {
    "facilitiesWord": "Strutture",
    "horaryWord": "Orario",
    "detailPage": {
        "sectionSchedules": {
            "title": "Orari",
            "openAlways": "Aperto tutti i giorni 24 ore",
            "empty": "Nessun orario specificato. Si prega di informarsi tramite chat",
            "days": {
                "Lunes": "Lunedì",
                "Martes": "Martedì",
                "Miércoles": "Mercoledì",
                "Jueves": "Giovedì",
                "Viernes": "Venerdì",
                "Sábado": "Sabato",
                "Domingo": "Domenica"
            }
        }
    }
}