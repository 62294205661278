import { lang } from "moment";
import language from "./language";

export default {
    "account": "Mi cuenta",
    "greeting": "¡Hola, {name}!",
    "my_stays": {
        "title": "Mis estancias",
        "subtitle_active": "Gestiona tus estancias",
        "subtitle_inactive": "Crea tu próxima estancia"
    },
    "personal_info": {
        "title": "Información personal",
        "description": "Configura tu información personal"
    },
    "language": {
        "title": "Preferencia de idioma",
        "description": "Selecciona tu idioma predeterminado"
    },
    "logout": "Cerrar sesión",
    "share_stay": "Compartir Estancia",
    "page_personal_info": {
        "title": "Información personal",
        "change_photo": "Cambiar foto",
        "change_password": "Cambiar contraseña",
        "btn_save": "Guardar",
        "form": {
            "name": {
                "label": "Nombre",
                "placeholder": "Introduce tu nombre"
            },
            "last_name": {
                "label": "Apellidos",
                "placeholder": "Introduce tu apellido"
            },
            "email": {
                "label": "Correo electrónico",
                "placeholder": "Introduce tu correo"
            },
            "number_phone": {
                "label": "Número de teléfono",
                "placeholder": "Introduce tu nº de teléfono"
            },
            "password": {
                "label": "Contraseña",
                "placeholder": "Introduce tu contraseña"
            }
        }
    },
    "book_next_stay": {
        "title": "Reserva tu próxima estancia",
        "description": "Planifica tu siguiente escapada"
    },
    "rewards": {
        "title": "Invita a un amigo",
        "description": "Comparte beneficios exclusivos",
        "button-recommend": "Recomendar a un amigo ahora",
        "referrals": {
            "title": "¡Recomienda a un amigo!",
            "discount": "{amount} de descuento",
            "description": "Envíale tu código de referente. Obtendrá {amount} de descuento gracias a tu código.",
        },
        "referent" : {
            "title": "¡Recomienda a un amigo y ganen ambos!",
            "titleReferred": "{amount} de descuento para el referido",
            "descerptionReferred": "Envíale tu código de referente. Obtendrá {amount} de descuento gracias a tu código.",
            "titleReferent": "Recibe {amount} de descuento",
            "descerptionReferent": "Una vez validado y utilizado el código del referido, conseguirás {amount} de descuento."
        },
        "titleReferentProfile" : "Gana dinero",
        "descriptionReferentProfile": "Refiere a un amigo para obtener beneficios" 
    },
};
