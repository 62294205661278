export default {
  "information": "Information",
  "facilities": "Einrichtungen",
  "lodging": "unterkunft",
  "apartment": "wohnung",
  "buttons_home": {
      "wifi": "Wifi",
      "call": "Anrufen",
      "standards": "Normen",
      "share_stay": "Aufenthalt Teilen"
  },
  "utils": {
      "see_more": "Mehr sehen",
      "see_all": "Alles sehen",
      "see_less": "Weniger sehen",
      "copy": "Kopieren",
      "from": "Von",
      "to": "Bis",
      "our_networks": "Unsere Netzwerke"
  }
};
