export default {
  "information": "Información",
  "facilities": "Instalaciones",
  "lodging": "alojamiento",
  "apartment": "apartamento",
  "buttons_home": {
      "wifi": "Wifi",
      "call": "Llamar",
      "standards": "Normas",
      "share_stay": "Compartir Estancia"
  },
  "utils": {
      "see_more": "Ver más",
      "see_all": "Ver todo",
      "see_less": "Ver menos",
      "copy": "Copiar",
      "from": "Desde",
      "to": "Hasta",
      "our_networks": "Nuestras Redes"
  }
};
