export default {
    "facilitiesWord":"Instalaciones",
    "horaryWord":"Horario",
    "detailPage": {
        "sectionSchedules": {
            "title": "Horarios",
            "openAlways": "Abierto todos los días las 24hs",
            "empty": "Sin horarios especificados. Consulta vía chat",
            "days": {
                "Lunes": "Lunes",
                "Martes": "Martes",
                "Miércoles": "Miércoles",
                "Jueves": "Jueves",
                "Viernes":"Viernes",
                "Sábado": "Sábado",
                "Domingo": "Domingo"
            }
        }
    }
}
