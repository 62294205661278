export default{
    "create":{
        "title": "Crie sua estadia",
        "check-date": {
            "label": "Data de Check-in/Check-out",
            "placeholder": "dd/mm/aaaa - dd/mm/aaaa",
        },
        "continue-button": "Criar e acessar a estadia",
    },
    "share": {
        "title": "Junte-se à nossa estadia em {hotel}",
        "text": "Junte-se agora e aproveite todas as vantagens do WebApp para aproveitar ao máximo nossa experiência"
    },
    "detail": {
        "title": "Minhas estadias",
        "createBtn": "Criar estadia",
        "editBtn": "Editar estadia"
    },
    "edit": {
        "title": "Editar estadia",
        "information-stay": "Informações da estadia",
        "input": {
            "medio-reserva": {
                "label": "Meio de reserva",
                "placeholder": "Selecionar meio de reserva",
                "n-room": "Nº quarto"
            }
        },
        "calendar": "Calendário",
        "guests": "Hóspedes",
        "saveBtn": "Salvar",
        "share": "Compartilhar estadia",
        "guest": "Hóspede",
        "completeCheckin": "Completar check-in",
        "waitingGuest": "Aguardando acesso do hóspede",
        "accessRoom": "Acessar estadia",
        "active": "Ativo",
        "pageWebHotel": "Site do hotel"
    },
    "reservation": {
        "title": "Reserve sua próxima estadia",
        "button-reservation": "Reservar",
        "title-page": "Selecione uma acomodação para reservar e nós o redirecionaremos para o site dela."

    }

}
