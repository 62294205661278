export default {
    "title": "Messaggi",
    "availabilty": "Disponibile",
    "not-availabilty": "Non disponibile",
    "languages-text": "Benvenuto nella chat!<br>Puoi comunicare con il nostro personale in queste lingue:",
    "automatic-response": "Risposta automatica",
    "input": "Scrivi un messaggio...",
    "horary-title": "Orari di chat",
    "nameDayLunes": "Lunedì",
    "nameDayMartes": "Martedì",
    "nameDayMiércoles": "Mercoledì",
    "nameDayJueves": "Giovedì",
    "nameDayViernes": "Venerdì",
    "nameDaySábado": "Sabato",
    "nameDayDomingo": "Domenica",
    "tabs": {
        "inbox": "ricevuti", 
        "chat": "chat"
    }
}
