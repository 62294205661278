export default {
  "apiError401": "Credenziali errate",
  "apiError404": "Servizio non disponibile",
  "apiError405_406": "Richiesta non valida",
  "apiError500": "Errore di connessione",
  "apiErrorUndefined": "Errore generale dell'applicazione",
  "invalidKey": "Chiave non valida",
  "recordSuccess": "Registrazione completata",
  "emailSuccess": "E-mail inviata",
  "passwordUpdated": "Password aggiornata",
  "copiedClipboard": "Copiato negli appunti",
  "guestDeleted": "Ospite eliminato",
  "urlClipboard": "Link copiato",
  "changeSave": "Modifiche salvate",
  "dataSave": "Dati salvati con successo"
};
