export default {
  "apiError401": "Incorrect credentials",
  "apiError404": "Service not available",
  "apiError405_406": "Invalid request",
  "apiError500": "Connection error",
  "apiErrorUndefined": "General application error",
  "invalidKey": "Invalid key",
  "recordSuccess": "Record completed",
  "emailSuccess": "Email sent",
  "passwordUpdated": "Password updated",
  "copiedClipboard": "Copied to clipboard",
  "guestDeleted": "Guest deleted",
  "urlClipboard": "Link copied",
  "changeSave": "Changes saved",
  "dataSave": "Data successfully saved"
};
  