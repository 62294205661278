export default {
    "header": {
        "home": "start",
        "facilities": "einrichtungen",
        "hotel": "hotel",
        "destination": "ziel",
        "experiences": "Erlebnisse",
        "messages": "nachrichten"
    },
    "dropdownLanguage": {
        "title": "Sprache"
    },
    "footer": {
        "legal-warning": "Rechtliche Warnung",
        "privacy-policy": "Datenschutzrichtlinie",
        "privacy-cookies": "Cookie-Richtlinie",
        "terms-conditions": "Nutzungsbedingungen"
    },
    "input-search": {
        "search": "Suchen",
        "placeholder": "Ziele, Erfahrungen...",
        "from": "von"
    }
    // weitere Übersetzungen...
  };
