export default {
  "title": "Dienstleistungen",
    "card-item": {
      "free": "Kostenlos"
    },
    "confort": {
      "title": "Komfort",
      "list-page": {},
      "detail-page": {}
    },
    "transport": {
      "title": "Transport",
      "list-page": {},
      "detail-page": {}
    },
    "activity": {
      "title": "Aktivitäten",
      "list-page": {},
      "detail-page": {}
    }
  }