export default{
    "log-or-register": {
        "title": "Entrar ou Registrar-se",
        "welcome-title": "Bem-vindo!",
        "welcome-msg": "Aproveite nossa WebApp e gerencie suas estadias em qualquer um de nossos hotéis.",
        "input": "Digite seu endereço de e-mail",
        "text-error": "O formato do e-mail digitado está incorreto",
        "continue-button": "Continuar",
        "continue-google": "Continuar com Google",
        "continue-fb": "Continuar com Facebook"
    },
    "log": {
        "title": "Entrar",
        "input-password": "Digite uma senha",
        "password-text-error": "A senha digitada está incorreta",
        "continue-button": "Continuar",
        "forgot-question": "Esqueceu sua senha?",
        "input-show-pass": "Mostrar",
        "input-hide-pass": "Ocultar",
    },
    "register": {
        "title": "Complete seu Registro",
        "input-name": "Digite seu nome",
        "input-email": "Digite seu endereço de e-mail",
        "input-email-error": "O formato do e-mail digitado está incorreto",
        "input-password": "Digite uma senha",
        "continue-button": "Aceitar e Continuar",
        "text-info-1": "enviará comunicações comerciais e promoções personalizadas por e-mail, SMS ou outros meios eletrônicos sobre produtos e serviços",
        "text-info-2": "Não quero receber mensagens promocionais.",
        "policies-text": "Ao selecionar Aceitar e Continuar, eu aceito a",
        "policies-title": "Política de Privacidade.",
        "the-chain": "A cadeia"
    },
    "reset-pass":{
        "title": "Alterar Senha",
        "input-placeholder": "Digite sua nova senha",
        "input-input-error": "A senha digitada está incorreta",
        "continue-button": "Alterar Senha",
        "greeting": "Olá",
        "title-reset": "Redefinir Senha",
        "msg": "Você solicitou um link para redefinir a senha da sua conta em nossa WebApp. Clique no botão seguinte para iniciar o processo.",
        "link": "Redefinir Senha",
    },
    "hotel-list":{
        "title": "Selecione o alojamento para criar a sua estadia na nossa WebApp",
        "amount-text": "acomodações da rede",
        "stars-text": "estrelas",
        "star-text": "estrela",
    }    
    
}
